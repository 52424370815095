

















































import { CacheService, Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SurveyService } from '@/services/survey-service';

@Component({
  components: {}
})

export default class addClassify extends Vue {
  @Prop({ default: true }) private title!: String;
  @Prop({ default: '' }) private chooseGroupId!: String;
  @Prop({ default: '' }) private userCode!: String;
  @Prop({ default: true }) private agentId!: String;
  @Prop({ default: true }) private groupFormVisible!: Boolean;
  @Prop({ default: true }) private surveyUserGroupings!: any;

  public labelPosition: String = 'top';
  public multipleSelection: any = [];
  public queryParams: any = { //查询参
    userGroupingName: '',
    pages: 1,
    size: 5,
    current: 1,
    total: 0,

  };
  private tableData: any[] = [];
  private applications: any[] = [];
  @Inject(CacheService) private cacheSvc!: CacheService;
  private userInfo: any = this.cacheSvc.get('user');
  @Inject(SurveyService) private SurveyService!: SurveyService;

  @Watch('chooseGroupId', { immediate: true, deep: true })
  onChangeValue(newVal: any, oldVal: any) {
    //console.log(newVal, oldVal, '监听chooseGroupId');
  }



  public async created(): Promise<void> {
    this.queryParams.userCode = this.userInfo.employeeId
    await this.getList();
  }

  public getRowKeys(row: any) {
    return row.userGroupingId;
  }

  //默认选中
  public checkData() {
    console.log(this.surveyUserGroupings,'传过来的选中数据')
    this.$nextTick(() => {
      this.tableData.forEach(row => {
        let indexs = JSON.stringify(this.surveyUserGroupings).indexOf(row.userGroupingId);
        if (indexs != -1) { //不等于-1 表示存在
          const checkRef = (this.$refs.multipleTable as any);
          checkRef.toggleRowSelection(row, true);
        }
      });
    });
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.queryParams.size = size;
    this.getList();
  }

  public handleCurrentChange(current: number) {
    this.queryParams.current = current;
    this.getList();
  }

  public search() {
    this.queryParams.current = 1;
    this.queryParams.size = 5;
    this.getList();
  }

  public handleSelectionChange(val: any) {
    console.log(val,'点击选择事件的返回列表')
    this.multipleSelection = val;
  }

  //获取数据源
  public async getList(): Promise<void> {
    console.log(this.queryParams, '选择用户组的请求参数');
    const res = await this.SurveyService.getSelectGroupList(this.queryParams);
    console.log(res, '选择用户组的返回参数');
    this.tableData = res.records;
    this.checkData();
    this.queryParams.total = res.total;
    this.queryParams.pages = res.pages;
    this.queryParams.size = res.size;
    this.queryParams.current = res.current;
  }

  //保存
  @Emit('groupSelectChange')
  public submitForm(): void {
    return this.multipleSelection;
  }

  //返回
  @Emit('groupSelectChange')
  public cancel(): string {
    return "取消";
  }

  public checkimage() {
    this.groupFormVisible = true;
  }
}
