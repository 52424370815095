





















































































































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SurveyService } from '@/services/survey-service';
import mapChooseGroup from '@/views/surveyConfig/mapChooseGroup.vue';

@Component({
  components: {
    mapChooseGroup
  }
})
export default class surveyTypeDictionary extends Vue {
  //修改
  public form: any = {
    size: 10,
    current: 1,
    total: 0,
    surveyUserGroupings: []//可见范围用户组
  };
  public formInfo: any = {
    isState: 0,
    typeName: null,
    userGroupingList: [],
    surveyTypeUserGroupings: []

  };
  public labelPosition: String = 'top';
  public title: string = '';
  private tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  // 修改 问卷类型对话框
  public dialogVisible: boolean = false;
  private currentRow: any = null;
  private dropDown: any[] = [];
  public groupVisible: boolean = false;
  public groupValue: any = [];
  public groupDataList: any = [];
  public groupRightItems: any = [];
  public groupQueryParams: any = { //查询参
    userGroupingName: '',
    pages: 1,
    size: 100000,
    current: 1,
    total: 0

  };
  private userGroupingId: any[] = [];
  private userGroupingList: any[] = [];
  private filteredUserGroupList = this.userGroupingList;
  public rules: any = {
    surveyTypeUserGroupings: [
      { required: true, message: '请选择可见范围', trigger: 'blur' }],
    typeName: [
      { required: true, message: '请输入类型名称', trigger: 'blur' }
    ]
  };
  // new S
  public groupTitle: String = '选择可见范围';
  public groupFormVisible: boolean = false;//用户组可见范围
  public chooseGroupId: String = '';

  // new E

  @Inject(SurveyService) private surveyService!: SurveyService;


  public async created(): Promise<void> {
    await this.getList();
    this.getDropDownSurveyType();
    this.getPersonnelScope();
    // this.getUserGroupingList();
    this.getGroupList();
  }

  public search() {
    this.form.current = 1;
    this.getList();
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }

  public switchChange(e: any) {
    console.log(e, 'switchValue');
  }

  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }

  public querySearch(query: any) {
    if (query) {
      this.filteredUserGroupList = this.userGroupingList.filter(item => item.userGroupingName.includes(query));
    } else {
      this.filteredUserGroupList = this.userGroupingList;
    }
  }


  //下载
  public async downLoadAll() {
    this.loading = true;
    var data_ = {
      type: '14'
    };
    const res = await this.surveyService.downLoadAll(data_, '公告提示.xlsx');
    this.loading = false;
  }

  //问卷类型列表 修改
  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.surveyService.listSurveyType(this.form);
    this.loading = false;
    let data: any = res.records;
    this.tableData = data;
    console.log(this.tableData, 'tableData');
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
  }

  //类型名称下拉
  public async getDropDownSurveyType(): Promise<void> {
    const res = await this.surveyService.dropDown();
    this.dropDown = res;
  }

  //可见范围下拉
  public async getPersonnelScope(): Promise<void> {
    const res = await this.surveyService.personnelScope();
    this.userGroupingId = res.filter((item: any) => item !== null);
    console.log(this.userGroupingId, 'this.userGroupingId');
  }

  //可见范围下拉-新增问卷类型
  public async getUserGroupingList(): Promise<void> {
    const res = await this.surveyService.userGroupingList();
    this.userGroupingList = res;
    //this.formInfo.userGroupingList=this.userGroupingList
  }

  //新增
  public async handAdd() {
    const addRef = (this.$refs.formInfo as any);
    console.log(addRef, '111');
    if (addRef) {
     // addRef.clearValidate();
    }
    this.groupValue = [];
    this.title = '添加线索类型';
    this.formInfo = {
      typeName: '',
      surveyTypeUserGroupings: []
    };
    // this.$nextTick(function() {
    //   this.groupRightItems = []
    // });
    this.dialogVisible = true;
    // console.log(this.groupRightItems,'groupRightItems')
    // const addRef = (this.$refs.formInfo as any);
    // console.log(addRef,'addRef')
    // if(addRef){
    //   addRef.clearValidate();
    // }

  }

  //删除
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };

    await this.$confirm('确定要删除吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });

    const res = await this.surveyService.deleteSurveyType(param);
    if (res === null) {
      this.getList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } else {
      this.$message({
        type: 'warning',
        message: res.body
      });
    }
  }

  //修改
  public async handUpdate(row: any) {
    this.title = '修改线索类型';

    this.formInfo = JSON.parse(JSON.stringify(row));
    console.log(this.formInfo, 'this.formInfo');
    this.formInfo.surveyGroupingsList = this.formInfo.surveyTypeUserGroupings.map((item: any) => item.userGroupingName).join(',');
    this.groupValue = this.formInfo.surveyTypeUserGroupings.map((item: any) => item.userGroupingId);
    this.dialogVisible = true;
  }

  public closeForm() {
    console.log(this.formInfo, 'formInfo');
    this.groupValue = [];
    this.formInfo.surveyTypeUserGroupings = [];
    const addRef = (this.$refs.formInfo as any);
    console.log(addRef, '111');
    //addRef.clearValidate();
    this.dialogVisible = false;

  }

  public closeForm2() {
    this.groupValue = [];
  }

  //保存
  public submitForm(): void {
    if (this.formInfo.surveyTypeUserGroupings.length == 0) {
      // this.$message({
      //   type: 'info',
      //   message: '请选择人员组范围'
      // });
      // const addRef = (this.$refs.formInfo as any)
      // addRef.validateField('surveyGroupingsList',
      //   (valid: any) => {
      //     if (valid) {
      //       this.$message({
      //           type: 'info',
      //           message: '请选择人员组范围'
      //         }
      //       );
      //     }
      //   })
    }
    const addRef = (this.$refs.formInfo as any);
    addRef.validate(async (valid: any) => {
      if (valid) {
        if (this.formInfo.id == undefined) {
          await this.surveyService.addSurveyType(this.formInfo).then((res: any) => {
            console.log(res, 'res');
            if(res == 'ok'){
              this.$message({
                message: '添加成功',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            }
          }).catch((err: any) => {
            console.log(err, 'err');

          });

        } else {
          await this.surveyService.updateSurveyType(this.formInfo).then((res: any) => {
            if(res == 'ok'){
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.dialogVisible = false;
              this.getList();
            }
          }).catch((err: any) => {
            console.log(err, 'err');

          });
        }
      } else {
        console.log(this.formInfo, 'error submit!!');
        return false;
      }
    });

  }

  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }

  //new S
  //用户组传值处理
  public groupSelectChange(e: any): void {
    // console.log(e, 'eeeeee');
    // if (e == false) {
    //   this.groupFormVisible = false;
    //   this.$message({
    //     type: 'info',
    //     message: '至少选择一个'
    //   });
    //   return;
    // }
    // this.form.surveyUserGroupings = [];
    // e.forEach((item: Dict<any>) => {
    //   let json: any = {};
    //   json.id = item.userGroupingId;
    //   json.userGroupingId = item.userGroupingId;
    //   json.userGroupingName = item.userGroupingName;
    //   this.form.surveyUserGroupings.push(json);
    // });
    // this.groupFormatData(this.form.surveyUserGroupings);
    // console.log(this.form.surveyUserGroupings, 'this.form.surveyUserGroupings');
    // this.groupFormVisible = false;
    if (e == '取消') {
      this.groupFormVisible = false;
      return;
    } else if (e) {
      this.form.surveyUserGroupings = [];
      e.forEach((item: Dict<any>) => {
        let json: any = {};
        json.id = item.userGroupingId;
        json.userGroupingId = item.userGroupingId;
        json.userGroupingName = item.userGroupingName;
        this.form.surveyUserGroupings.push(json);
      });
      this.groupFormatData(this.form.surveyUserGroupings);
    }
    this.groupFormVisible = false;
  }

  //应用分组数据回显处理
  public async groupFormatData(data: any) {
    console.log(data, 'data11111');
    //应用分组数组回显处理
    let arr: any = [];
    for (var item = 0; item < data.length; item++) {
      arr.push(data[item].userGroupingName);
    }
    console.log(arr, '应用分组数据回显处理');
    //this.$nextTick(function() {
    let list = arr.join(',');
    this.$set(this.form, 'surveyGroupingsList', list);
    console.log(list, 'surveyGroupingsList');
    this.form.surveyGroupingsList = list;
    //});
  }

  public groupFilterMethod(query: any, item: any) {
    return item.userGroupingName.indexOf(query) > -1;
  }

  public groupSubmit() {
    console.log(this.groupRightItems, 'this.groupRightItems');

    this.formInfo.surveyGroupingsList = this.groupRightItems.map((item: any) => item.userGroupingName).join(',');
    this.formInfo.surveyTypeUserGroupings = this.groupRightItems;
    console.log(this.formInfo.surveyTypeUserGroupings, ' this.form.userGroupingList');
    if (this.groupRightItems.length > 0) {
      this.rules.surveyTypeUserGroupings
        = [{ required: false }];
    } else {
      this.rules.surveyTypeUserGroupings = [
        { required: true, message: '请选择可见范围', trigger: ['focus'] }];
    }

    this.groupVisible = false;
  }

  public groupTransferChange(value: any, direction: any, movedKeys: any) {
    console.log(value, 'val');
    console.log(this.groupRightItems, 'this.groupRightItems ');
    console.log(movedKeys, 'movedKeys');
    if (direction === 'right') {
      this.groupRightItems = this.groupDataList.filter((item: any) => value.includes(item.userGroupingId));
      console.log('右侧列表对象：', this.groupRightItems);
    }
    if (direction === 'left') {
      this.groupRightItems = this.groupDataList.filter((item: any) => value.includes(item.userGroupingId));
      console.log('右侧列表对象：', this.groupRightItems);
    }
  }

  public groupChoose() {

    this.groupVisible = true;
  }

  public async getGroupList(): Promise<void> {

    const res = await this.surveyService.userGroupingList();
    this.groupDataList = res.map((item: any, index: any) => {
      return {
        ...item,
        key: item.userGroupingId,
        lable: item.userGroupingName,
        userGroupingId: item.userGroupingId
      };
    });
    this.groupQueryParams.total = res.total;
    this.groupQueryParams.pages = res.pages;
    this.groupQueryParams.size = res.size;
    this.groupQueryParams.current = res.current;
  }

}
